Vue.component('centralContainer', {
    data:function(){
        return {homeSections:[]};
    },
    methods: {
        forceUpdate: function () {
            //console.log('Refresh from centralContainer');
            this.homeSections=[
                {sectionValue:0, type:'slider'},
                {sectionValue:1, type:'itemCard' , title:'Más ofertas aquí'},
                {sectionValue:4, type:'slider',heigtht:'140px'},
                {sectionValue:2, type:'itemCard' , title:'Productos Ecológicos'},
                {sectionValue:5, type:'slider',heigtht:'140px'},
                {sectionValue:3, type:'itemCard' , title:"Nuevos Lanzamientos" },
            ];
            let itemCodes = new Map();
            for(let homeItemCode of this.$store.getters.getHomeItems){
                if(!itemCodes.has(homeItemCode.fields.ItemCode)) {
                    let tempItem = this.$store.getters.getItemByCode(homeItemCode.fields.ItemCode);
                    if(tempItem)
                        itemCodes.set(homeItemCode.fields.ItemCode, tempItem);
                }
            }
            this.$store.dispatch("updatePrices",Array.from(itemCodes.values()));
        },
    },
    mounted:function(){
        this.forceUpdate();
    },
    template:`<div v-if="$store.getters.getAppState!='onload'">
                  <template v-for="homeSection of homeSections">
                      <homeItemSection :section="homeSection"></homeItemSection>
                  </template>
              </div>`
});